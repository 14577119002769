import React from 'react'
import { Autocomplete, Avatar, Button, Chip, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, SvgIcon, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from "yup"
import PaperClipIcon from '@heroicons/react/24/solid/PaperClipIcon';
import { authPostRequest, postRequest } from '../../services/api-service';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';

function CustomForm({
    fields,
    values,
    url,
    handleClickOpenSuccessAlertDialog,
    verifyPaymentDetails
}) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState("");
    const [error, setError] = React.useState("");
    const formInfo = useSelector((state) => state.FormInformationReducer);
    const schema = Yup.object().shape(
        fields.reduce((obj, field) => {
            if (field.type === 'email') {
                if (field.notRequired) {
                    obj[field.name] = Yup.string().email(`${field.label} lazima iwe email`)
                        .required(`${field.label} ni lazima`).optional()
                } else {
                    obj[field.name] = Yup.string().email(`${field.label} lazima iwe email`)
                        .required(`${field.label} ni lazima`)
                }
            } else {
                if (field.notRequired) {
                    obj[field.name] = Yup.string().min(1, `${field.label} angalau herufi moja`)
                        .required(`${field.label} ni lazima`).optional()
                } else {
                    obj[field.name] = Yup.string().min(1, `${field.label} angalau herufi moja`)
                        .required(`${field.label} ni lazima`)
                }
            }
            return obj
        }, {})
    )
    const [serverError, setServerError] = React.useState("")

    return (
        <Formik
            initialValues={{ ...values[0] }}
            validationSchema={schema}
            onSubmit={(values, helpers) => {
                // console.log("onSubmit", JSON.stringify(values, null, 2))
                helpers.setSubmitting(true);
                let body = values
                for (let i = 0; i < fields.length; i++) {
                    if (fields[i].type === "date") {
                        body = { ...body, [fields[i].name]: values[fields[i].name].format("DD/MM/YYYY") }
                    } else if (fields[i].type === "dateTime") {
                        body = { ...body, [fields[i].name]: values[fields[i].name].format('YYYY-MM-DD HH:mm:ss.SSS') }
                    } else if (fields[i].type === "number") {
                        body = { ...body, [fields[i].name]: parseInt(values[fields[i].name]) }
                    }
                }
                postRequest(
                    url,
                    body,
                    (data) => {
                        helpers.resetForm()
                        helpers.setSubmitting(false)
                        if (values?.["payment_method"] === "DELIVERY" && handleClickOpenSuccessAlertDialog) {
                            handleClickOpenSuccessAlertDialog();
                        }
                        if (values?.["payment_method"] === "ONLINE") {
                            verifyPaymentDetails(data.id);
                        }
                    },
                    (error) => {
                        if (error?.response?.data?.message) {
                            setServerError(error.response.data.message[0])
                        } else if (error?.response?.data) {
                            helpers.setErrors(error?.response?.data)
                        }
                        helpers.setSubmitting(false)
                    },
                    fields.some(item => item.type === "file") ? true : false
                )
            }}
        >
            {({ isSubmitting, values, touched, errors, handleChange, handleBlur, setFieldValue, setValues }) => (
                <Form
                    noValidate
                    autoComplete="off"
                >
                    {fields.map((field, index) => {
                        if (field.label === "Kata" && values?.["region_id"] !== 2) {
                            return null;
                        }
                        if (field.label === "Mtaa Karibu na" && values?.["region_id"] !== 2) {
                            return null;
                        }
                        if (field.label === "Namba ya Simu Kwa Ajili Ya Malipo" && values?.["payment_method"] === "DELIVERY") {
                            return null;
                        }

                        return (
                            <React.Fragment key={index}>
                                {field.type === "select" ?
                                    <TextField
                                        id={field.name}
                                        select
                                        margin='normal'
                                        label={field.label}
                                        value={values[field.name]}
                                        error={Boolean(errors[field.name] && touched[field.name])}
                                        helperText={touched[field.name] && errors[field.name]}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            setFieldValue(field.name, event.target.value)
                                            dispatch({
                                                type: "FORM_INFO",
                                                payload: {
                                                    ...formInfo,
                                                    [field.name]: event.target.value
                                                },
                                            })
                                        }}
                                        fullWidth
                                        sx={{}}
                                    >
                                        {field.items.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item.value}
                                            >
                                                {item?.label ? item?.label : item.value}
                                            </MenuItem>
                                        ))}
                                    </TextField> :
                                    field.type === "radio" ?
                                        <FormControl margin='normal'>
                                            <FormLabel id="demo-controlled-radio-buttons-group">{field.label}</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={values[field.name]}
                                                onChange={(event) => {
                                                    setFieldValue(field.name, event.target.value)
                                                }}
                                            >
                                                {field.items.map((item, index) => (
                                                    <FormControlLabel key={index} value={item.value} control={<Radio color='info' />} label={item?.label} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl> :
                                        field.type === "file" ?
                                            <OutlinedInput
                                                id={field.name}
                                                placeholder={(values[field.name]?.name) || (values[field.name] !== null) ? "" : field.label}
                                                readOnly
                                                type="text"
                                                margin="none"
                                                fullWidth
                                                error={Boolean(errors[field.name] && touched[field.name])}
                                                startAdornment={(
                                                    <InputAdornment position="start">
                                                        {(values[field.name]?.name || values[field.name] !== null) &&
                                                            <Chip
                                                                label={values[field.name]?.name ? values[field.name]?.name : "Image"}
                                                                onDelete={() => {
                                                                    setValues({ ...values, [field.name]: null })
                                                                }}
                                                            />
                                                        }
                                                    </InputAdornment>
                                                )}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        {(values[field.name]?.name) || (values[field.name] !== null) ?
                                                            <Avatar
                                                                variant='rounded'
                                                                alt={values[field.name]?.name}
                                                                src={values[field.name]?.name ? URL.createObjectURL(values[field.name]) : values[field.name]}
                                                            /> :
                                                            <IconButton
                                                                aria-label="upload picture"
                                                                component="label"
                                                            >
                                                                <input
                                                                    hidden
                                                                    accept="image/*"
                                                                    type="file"
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        if (e.target.files) {
                                                                            setValues({ ...values, [field.name]: e.target.files[0] })
                                                                        }
                                                                    }}
                                                                />
                                                                <SvgIcon fontSize='small'>
                                                                    <PaperClipIcon />
                                                                </SvgIcon>
                                                            </IconButton>
                                                        }
                                                    </InputAdornment>
                                                }
                                                sx={{ mt: 2 }}
                                            /> :
                                            field.type === "time" ?
                                                <TimePicker
                                                    label={field.label}
                                                    value={values[field.name]}
                                                    onChange={(newValue) => {
                                                        setFieldValue(field.name, newValue)
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            margin: "normal",
                                                            error: Boolean(errors[field.name] && touched[field.name]),
                                                            helperText: touched[field.name] && errors[field.name],
                                                            onBlur: handleBlur,
                                                            fullWidth: true
                                                        }
                                                    }}
                                                /> :
                                                field.type === "date" ?
                                                    <DatePicker
                                                        label={field.label}
                                                        value={values[field.name]}
                                                        onChange={(newValue) => {
                                                            setFieldValue(field.name, newValue)
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                                margin: "normal",
                                                                error: Boolean(errors[field.name] && touched[field.name]),
                                                                helperText: touched[field.name] && errors[field.name],
                                                                onBlur: handleBlur,
                                                                fullWidth: true
                                                            }
                                                        }}
                                                    /> :
                                                    field.type === "dateTime" ?
                                                        <DateTimePicker
                                                            disablePast
                                                            label={field.label}
                                                            value={values[field.name]}
                                                            onChange={(newValue) => {
                                                                setFieldValue(field.name, newValue)
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    margin: "normal",
                                                                    error: Boolean(errors[field.name] && touched[field.name]),
                                                                    helperText: touched[field.name] && errors[field.name],
                                                                    onBlur: handleBlur,
                                                                    fullWidth: true
                                                                }
                                                            }}
                                                        /> :
                                                        field.type === "search" ?
                                                            <Autocomplete
                                                                options={options}
                                                                getOptionLabel={(option) =>
                                                                    `${option[field.searchLabel].toString()}`
                                                                }
                                                                filterOptions={(x) => x}
                                                                noOptionsText={isLoading ? "Loading..." : "No items"}
                                                                includeInputInList
                                                                filterSelectedOptions
                                                                onChange={(event, value) => {
                                                                    if (value) {
                                                                        setFieldValue(field.name, value.id)
                                                                    }
                                                                }}
                                                                renderOption={(props, option) => {

                                                                    return (
                                                                        <li {...props}>
                                                                            <List sx={{ width: "100%" }}>
                                                                                <ListItem>
                                                                                    {field.searchImage &&
                                                                                        <ListItemAvatar>
                                                                                            <Avatar src={option[field.searchImage][0][field.searchImageFirstItem] || option[field.searchImage]} />
                                                                                        </ListItemAvatar>
                                                                                    }
                                                                                    <ListItemText
                                                                                        primary={`${option[field.searchLabel]}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </li>
                                                                    )
                                                                }}
                                                                onInputChange={() => setOptions([])}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={field.label}
                                                                        color='secondary'
                                                                        fullWidth
                                                                        margin='normal'
                                                                        value={value}
                                                                        onChange={(event) => {
                                                                            setValue(event.target.value)
                                                                            authPostRequest(
                                                                                field.searchUrl,
                                                                                { ...field.searchBody, query: event.target.value },
                                                                                setIsLoading,
                                                                                (data) => {
                                                                                    setOptions(data.results)
                                                                                    setIsLoading(false)
                                                                                },
                                                                                (error) => {
                                                                                    error?.response?.data?.message && setError(error.response.data.message[0])
                                                                                    setIsLoading(false)
                                                                                }
                                                                            )
                                                                        }}
                                                                        onFocus={(event) => {
                                                                            setValue(event.target.value)
                                                                            authPostRequest(
                                                                                field.searchUrl,
                                                                                { ...field.searchBody, query: event.target.value },
                                                                                setIsLoading,
                                                                                (data) => {
                                                                                    setOptions(data.results)
                                                                                    setIsLoading(false)
                                                                                },
                                                                                (error) => {
                                                                                    error?.response?.data?.message && setError(error.response.data.message[0])
                                                                                    setIsLoading(false)
                                                                                }
                                                                            )
                                                                        }}
                                                                    />
                                                                )}
                                                            /> :
                                                            <TextField
                                                                id={field.name}
                                                                multiline
                                                                required={field?.notRequired === false}
                                                                name={field.name}
                                                                type={field.type}
                                                                label={field.label}
                                                                margin="normal"
                                                                fullWidth
                                                                value={values[field.name]}
                                                                error={Boolean(errors[field.name] && touched[field.name])}
                                                                helperText={touched[field.name] && errors[field.name]}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            />
                                }
                            </React.Fragment>
                        )
                    })}
                    <Typography
                        color="error"
                        sx={{
                            mt: 2,
                        }}
                    >
                        {serverError}
                        {error}
                    </Typography>
                    <Button
                        fullWidth
                        variant='contained'
                        color='error'
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ?
                            "Subiri..." :
                            "Bofya Hapa Kuweka Oda"
                        }
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default CustomForm