import React from 'react'
import Layout from '../../components/Layout/Layout'
// import ProductsSection from '../../components/Home/ProductsSection'
import ProductsList from '../../components/Home/ProductsList'
import { postRequest } from '../../services/api-service';
import { getAllProductsUrl } from '../../seed/url';
import ProductsListShimmer from '../../components/Home/ProductsListShimmer';
import { Box, Container } from '@mui/material';


function Home() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [products, setProducts] = React.useState([]);

  const getRelatedProducts = React.useCallback(() => {
    setIsLoading(true)
    postRequest(
      getAllProductsUrl,
      {
        status: "AVAILABLE",
        platform: "PORTAL",
        limit: 100,
        page: 1,
        sort: "id desc"
      },
      (data) => {
        setProducts(data.results)
        setIsLoading(false)
      },
      (error) => {
        setIsLoading(false)
      },
    )
  }, [])

  React.useEffect(() => {
    getRelatedProducts()
  }, [getRelatedProducts])

  return (
    <Layout>
      {/* <ProductsSection
        sectionTitle={"Bidha Zinazopendwa"}
        products={products}
      />
      <ProductsSection
        sectionTitle={"Bidha Mpya"}
        products={products}
      /> */}
      {isLoading &&
        <>
          <Container maxWidth="lg" sx={{ display: { xs: 'none', md: 'block' }, }}>
            <ProductsListShimmer />
          </Container>
          <Box sx={{ display: { xs: 'block', md: 'none' }, }}>
            <ProductsListShimmer />
          </Box>
        </>
      }
      {!isLoading &&
        <>
          <Container maxWidth="lg" sx={{ display: { xs: 'none', md: 'block' }, }}>
            <ProductsList
              sectionTitle={"Bidhaa kwa matumizi yako"}
              products={products}
            />
          </Container>
          <Box sx={{ display: { xs: 'block', md: 'none' }, }}>
            <ProductsList
              sectionTitle={"Bidhaa kwa matumizi yako"}
              products={products}
            />
          </Box>
        </>
      }
    </Layout>
  )
}

export default Home