import React from 'react'
import Layout from '../../components/Layout/Layout'
import ProductsList from '../../components/Home/ProductsList'
import { getAllProductsUrl } from '../../seed/url';
import { postRequest } from '../../services/api-service';
import ProductsListShimmer from '../../components/Home/ProductsListShimmer';
import { Box, Container } from '@mui/material';

function Products() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [products, setProducts] = React.useState([]);

    const getRelatedProducts = React.useCallback(() => {
        setIsLoading(true)
        postRequest(
            getAllProductsUrl,
            {
                status: "AVAILABLE",
                limit: 100,
                page: 1,
                sort: "id desc"
            },
            (data) => {
                setProducts(data.results)
                setIsLoading(false)
            },
            (error) => {
                setIsLoading(false)
            },
        )
    }, [])

    React.useEffect(() => {
        getRelatedProducts()
    }, [getRelatedProducts])

    return (
        <Layout>
            {isLoading &&
                <>
                    <Container maxWidth="lg" sx={{ display: { xs: 'none', md: 'block' }, }}>
                        <ProductsListShimmer />
                    </Container>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, }}>
                        <ProductsListShimmer />
                    </Box>
                </>
            }
            {!isLoading &&
                <>
                    <Container maxWidth="lg" sx={{ display: { xs: 'none', md: 'block' }, }}>
                        <ProductsList
                            sectionTitle={"Bidhaa kwa matumizi yako"}
                            products={products}
                        />
                    </Container>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, }}>
                        <ProductsList
                            sectionTitle={"Bidhaa kwa matumizi yako"}
                            products={products}
                        />
                    </Box>
                </>
            }
        </Layout>
    )
}

export default Products