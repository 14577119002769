import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Box, Skeleton } from '@mui/material'
import { postRequest } from '../../services/api-service';
import { getAllProductsCategoriesBannersByPaginationUrl } from '../../seed/url';

function Banner() {
    const [productBanners, setProductBanners] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const getProductBanners = React.useCallback(() => {
        postRequest(
            getAllProductsCategoriesBannersByPaginationUrl,
            {
                sort: "id desc",
                limit: 5,
                page: 1
            },
            (data) => {
                setProductBanners(data.results)
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
            },
        )
    }, [])

    React.useEffect(() => {
        getProductBanners()
    }, [getProductBanners])

    return (
        <>
            {isLoading &&
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    sx={{
                        height: { xs: "150px", sm: "250px", md: "300px", lg: "350px", xl: "600px" }
                    }}
                />
            }
            {!isLoading &&
                <Carousel
                    indicators={false}
                    stopAutoPlayOnHover={false}
                    sx={{
                        height: { xs: "150px", sm: "250px", md: "300px", lg: "350px", xl: "600px" }
                    }}
                >
                    {productBanners.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Item item={item} index={index} />
                            </React.Fragment>
                        )
                    })}
                </Carousel>
            }
        </>
    )
}

export default Banner;

function Item({ item, index }) {
    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <Box
                key={index}
                component='img'
                sx={{
                    width: "100vw",
                }}
                alt="Banner"
                src={item.product_image}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.1)',
                }}
            >
            </Box>
        </Box>
    )
}