import React from 'react'
import { Dialog, DialogContent, Slide, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import successAnimation from "../../animations/loader_animation.json"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function LoadingModal({
    open,
    message
}) {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="data-feed-form-dialog"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogContent>
                <Lottie
                    animationData={successAnimation}
                    loop={true}
                />
                <Typography variant='h6' align='center'>
                    {message}
                </Typography>
            </DialogContent>
        </Dialog>
    )
}

export default LoadingModal