import React from 'react'
import Header from './Header'
import { Box } from '@mui/material'
import Banner from './Banner'
import { useLocation } from 'react-router-dom';

function Layout({ children }) {
    const router = useLocation();
    const { pathname } = router;

    return (
        <>
            <Header />
            {pathname === "/" && <Banner />}
            <Box
                component="main"
                sx={{ mx: 2 }}
            >
                {children}
            </Box>
        </>
    )
}

export default Layout