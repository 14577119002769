import React from 'react'
import Layout from '../../components/Layout/Layout'
import { placeOrderFormFields } from '../../seed/form-fields'
import { useSelector } from 'react-redux'
import { getRequest, postRequest } from '../../services/api-service'
import { getAllDistrictsUrl, getAllRegionsUrl, getAllWardsUrl, getProductUrl, verifyPaymentDetailsUrl } from '../../seed/url'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ViewProductShimmer from '../../components/Home/ViewProductShimmer'
import ViewProductDetails from '../../components/Home/ViewProductDetails';
import { Box, Container } from '@mui/material';
import SuccessAlertModal from '../../components/Components/SuccessAlertModal';
import FailedAlertModal from '../../components/Components/FailedAlertModal';
import LoadingModal from '../../components/Components/LoadingModal';

function ViewProduct() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const trackerParam = params.get("tracker");
    const { product_name } = useParams();
    const formInfo = useSelector((state) => state.FormInformationReducer);
    const [isLoading, setIsLoading] = React.useState(true);
    const [regions, setRegions] = React.useState([]);
    const [formFields, setFormFields] = React.useState(placeOrderFormFields)
    const [openSuccessAlertDialog, setOpenSuccessAlertDialog] = React.useState(false);
    const [openFailedAlertDialog, setOpenFailedAlertDialog] = React.useState(false);
    const [openLoadingDialog, setOpenLoadingDialog] = React.useState(false);
    const [product, setProduct] = React.useState({});
    const values = [
        {
            full_name: "",
            phone_number: "",
            region_id: "",
            district_id: "",
            ward_id: 0,
            street: "",
            product_quantity: 1,
            payment_method: "DELIVERY",
            payment_phone_number: "",
            product_id: product?.id,
            tracking_id: trackerParam || ""
        },
    ];

    const handleClickOpenSuccessAlertDialog = () => {
        setOpenSuccessAlertDialog(true);
    };

    const handleClickOpenFailedAlertDialog = () => {
        setOpenFailedAlertDialog(true);
    };

    const handleCloseFailedAlertDialog = () => {
        setOpenFailedAlertDialog(false);
    };

    const handleClickOpenLoadingDialog = () => {
        setOpenLoadingDialog(true);
    };

    const handleCloseLoadingDialog = () => {
        setOpenLoadingDialog(false);
    };

    const verifyPaymentDetails = React.useCallback((orderId) => {
        handleClickOpenLoadingDialog()
        postRequest(
            verifyPaymentDetailsUrl,
            {
                id: orderId
            },
            (data) => {
                handleCloseLoadingDialog()
                handleClickOpenSuccessAlertDialog()
            },
            (error) => {
                handleCloseLoadingDialog()
                handleClickOpenFailedAlertDialog()
            },
        )
    }, [])

    const getAllDistricts = React.useCallback((regionId) => {
        postRequest(
            getAllDistrictsUrl,
            {
                id: regionId
            },
            (data) => {
                const newDistricts = data.map((category) => {
                    const newItem = {};
                    ["label", "value"].forEach((item) => {
                        if (item === "label") {
                            newItem[item] = category.district_name;
                        }
                        if (item === "value") {
                            newItem[item] = category.id;
                        }
                    });
                    return newItem;
                });
                let newFormFields = formFields;
                newFormFields[3].items = newDistricts;
                setFormFields(newFormFields);
            },
            (error) => {
                let newFormFields = formFields;
                newFormFields[3].items = [];
                setFormFields(newFormFields);
            },
        )
    }, [formFields])

    const getAllWards = React.useCallback((districtId) => {
        postRequest(
            getAllWardsUrl,
            {
                id: districtId
            },
            (data) => {
                const newDistricts = data.map((category) => {
                    const newItem = {};
                    ["label", "value"].forEach((item) => {
                        if (item === "label") {
                            newItem[item] = category.ward_name;
                        }
                        if (item === "value") {
                            newItem[item] = category.id;
                        }
                    });
                    return newItem;
                });
                let newFormFields = formFields;
                newFormFields[4].items = newDistricts;
                setFormFields(newFormFields);
            },
            (error) => {
                let newFormFields = formFields;
                newFormFields[4].items = [];
                setFormFields(newFormFields);
            },
        )
    }, [formFields])

    const getProduct = React.useCallback(() => {
        setIsLoading(true)
        postRequest(
            getProductUrl,
            {
                product_name: product_name
            },
            (data) => {
                setProduct(data)
                setIsLoading(false)
            },
            (error) => {
                navigate("/");
                setIsLoading(false)
            },
        )
    }, [navigate, product_name])

    React.useEffect(() => {
        getRequest(
            getAllRegionsUrl,
            (data) => {
                setRegions(data);
            },
            (error) => { }
        );
    }, [])

    React.useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);

        // If you have cleanup logic, you can return a function
        // from the useEffect to handle it when the component is unmounted
        return () => {
            // Cleanup logic if needed
        };
    }, []);

    React.useEffect(() => {
        if (regions.length > 0) {
            const newRegions = regions.map((region) => {
                const newItem = {};
                ["label", "value"].forEach((item) => {
                    if (item === "label") {
                        newItem[item] = region.region_name;
                    }
                    if (item === "value") {
                        newItem[item] = region.id;
                    }
                });
                return newItem;
            });
            let newFormFields = formFields;
            newFormFields[2].items = newRegions;
            setFormFields(newFormFields);
        }
    }, [regions, formFields])

    React.useEffect(() => {
        if (formInfo?.region_id) {
            getAllDistricts(formInfo?.region_id)
        }
    }, [formInfo, getAllDistricts])

    React.useEffect(() => {
        if (formInfo?.district_id) {
            getAllWards(formInfo?.district_id)
        }
    }, [formInfo, getAllWards])

    React.useEffect(() => {
        getProduct()
    }, [getProduct])

    return (
        <Layout>
            {openSuccessAlertDialog &&
                <SuccessAlertModal
                    open={openSuccessAlertDialog}
                    buttonTitle={"Ona Bidhaa Zaidi"}
                    onClick={() => { navigate("/"); }}
                />
            }
            {openFailedAlertDialog &&
                <FailedAlertModal
                    open={openFailedAlertDialog}
                    buttonTitle={"Weka Oda"}
                    onClick={() => { handleCloseFailedAlertDialog(); }}
                />
            }
            {openLoadingDialog &&
                <LoadingModal
                    open={openLoadingDialog}
                    message={"Inachakata Malipo..."}
                />
            }
            {isLoading &&
                <>
                    <Container maxWidth="md" sx={{ display: { xs: 'none', md: 'block' }, }}>
                        <ViewProductShimmer />
                    </Container>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, }}>
                        <ViewProductShimmer />
                    </Box>
                </>
            }
            {!isLoading &&
                <>
                    <Container maxWidth="md" sx={{ display: { xs: 'none', md: 'block' }, }}>
                        <ViewProductDetails
                            product={product}
                            values={values}
                            handleClickOpenSuccessAlertDialog={handleClickOpenSuccessAlertDialog}
                            verifyPaymentDetails={verifyPaymentDetails}
                        />
                    </Container>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, }}>
                        <ViewProductDetails
                            product={product}
                            values={values}
                            handleClickOpenSuccessAlertDialog={handleClickOpenSuccessAlertDialog}
                            verifyPaymentDetails={verifyPaymentDetails}
                        />
                    </Box>
                </>
            }
        </Layout>
    )
}

export default ViewProduct