import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home/Home";
import Products from "../pages/Products/Products";
import ViewProduct from "../pages/ViewProduct/ViewProduct";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <Home />,
    },
    {
        path: "products",
        element: <Products />,
    },
    {
        path: "product/:product_name",
        element: <ViewProduct />,
    },
]);