import { Box, Skeleton } from '@mui/material'
import React from 'react'

function ViewProductShimmer() {

    return (
        <Box
            sx={{ my: 3, mx: 0.5 }}
        >
            <Skeleton
                variant="rectangular"
                width="100%"
                sx={{
                    height: { xs: "350px", sm: "400px", md: "550px", lg: "700px", xl: "750px" }
                }}
            />
            <Skeleton sx={{ my: 1 }} />
            <Skeleton sx={{ my: 1 }} />
            <Skeleton sx={{ my: 1 }} />
            <Skeleton sx={{ my: 1 }} />
            <Skeleton sx={{ my: 1 }} />
            <Skeleton sx={{ my: 1 }} />
            <Skeleton sx={{ my: 1 }} />
            <Skeleton sx={{ my: 1 }} />
            <Skeleton width="60%" sx={{ my: 1 }} />
            <Box
                sx={{
                    display: "flex",
                    my: 1
                }}
            >
                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
                <Skeleton width="100%" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    my: 1
                }}
            >
                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
                <Skeleton width="100%" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    my: 1
                }}
            >
                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
                <Skeleton width="90%" />
            </Box>
        </Box>
    )
}

export default ViewProductShimmer