import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { formatMoney } from '../../utils/constant'
import Carousel from 'react-material-ui-carousel'
import './ProductDescription.css';
import CustomForm from '../Components/CustomForm';
import { placeOrderFormFields } from '../../seed/form-fields';
import { createProductOrderUrl } from '../../seed/url';

function ViewProductDetails({
    product,
    values,
    handleClickOpenSuccessAlertDialog,
    verifyPaymentDetails
}) {

    return (
        <>
            {product &&
                <Card sx={{ minWidth: 345, mt: 3 }}>
                    <CardActionArea>
                        <Carousel
                            indicators={product?.product_files.length > 1}
                            autoPlay={false}
                        >
                            {product?.product_files && product?.product_files.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {item.file_type === "VIDEO" ?
                                            <video
                                                width="100%"
                                                controls
                                                src={`${item.video_url}`}
                                            /> :
                                            item.file_type === "YOUTUBE" ?
                                                <iframe
                                                    width="100%"
                                                    height="100%"
                                                    src={`${item.video_url}`}
                                                    title={item.product_name}
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen
                                                    style={{
                                                        borderRadius: "8px",
                                                        overflow: "hidden",
                                                    }}
                                                /> :
                                                <CardMedia
                                                    key={index}
                                                    component="img"
                                                    image={item?.image_url}
                                                    alt="Picha ya Bidhaa"
                                                />
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </Carousel>
                        <CardContent sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 4
                        }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    flexWrap: "wrap"
                                }}
                            >
                                <Typography gutterBottom variant="h6" component="div" color="text.secondary" sx={{ textDecoration: "line-through" }}>
                                    {formatMoney(product.product_amount)}
                                </Typography>
                                <Typography color="primary" gutterBottom variant="h6" component="div">
                                    {formatMoney(product.product_promotion_amount)}
                                </Typography>
                            </Box>
                            <div
                                className="product-description"
                                dangerouslySetInnerHTML={{ __html: product?.description_in_html_form }}
                            />
                            <Box
                                sx={{
                                    backgroundColor: "info.main",
                                    borderRadius: 1,
                                    padding: 2,
                                    color: "white"
                                }}
                            >
                                <Typography textAlign="center">
                                    ILI KUAGIZA, TAFADHALI WEKA MAELEZO YAKO KATIKA VISANDUKU VILIVYO HAPA CHINI.
                                </Typography>
                                <CustomForm
                                    fields={placeOrderFormFields}
                                    values={values}
                                    url={createProductOrderUrl}
                                    handleClickOpenSuccessAlertDialog={handleClickOpenSuccessAlertDialog}
                                    verifyPaymentDetails={verifyPaymentDetails}
                                />
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            }
        </>
    )
}

export default ViewProductDetails