import { Box, Grid, Skeleton } from '@mui/material'
import React from 'react'

function ProductsListShimmer() {

    return (
        <>
            <Box
                sx={{
                    my: { xs: 2, sm: 3, md: 4, lg: 5, xl: 6 },
                    fontSize: { xs: "20px", sm: "25px", md: "30px", lg: "35px", xl: "40px" }
                }}
            >
                <Skeleton
                    width="60%"
                    sx={{
                        height: { xs: "20px", sm: "25px", md: "30px", lg: "35px", xl: "40px" }
                    }}
                />
            </Box>
            <Grid container spacing={3}>
                <Grid item lg={3} sm={6} xl={3} xs={6}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            height: { xs: "300px", sm: "350px", md: "4500px", lg: "500px", xl: "550px" }
                        }}
                    />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={6}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            height: { xs: "300px", sm: "350px", md: "4500px", lg: "500px", xl: "550px" }
                        }}
                    />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={6}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            height: { xs: "300px", sm: "350px", md: "4500px", lg: "500px", xl: "550px" }
                        }}
                    />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={6}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            height: { xs: "300px", sm: "350px", md: "4500px", lg: "500px", xl: "550px" }
                        }}
                    />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={6}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            height: { xs: "300px", sm: "350px", md: "4500px", lg: "500px", xl: "550px" }
                        }}
                    />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={6}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            height: { xs: "300px", sm: "350px", md: "4500px", lg: "500px", xl: "550px" }
                        }}
                    />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={6}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            height: { xs: "300px", sm: "350px", md: "4500px", lg: "500px", xl: "550px" }
                        }}
                    />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={6}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            height: { xs: "300px", sm: "350px", md: "4500px", lg: "500px", xl: "550px" }
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ProductsListShimmer