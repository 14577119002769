import React from 'react'
import { Button, Dialog, DialogContent, Slide } from '@mui/material'
import Lottie from 'lottie-react'
import failedAnimation from "../../animations/failed_animation.json"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function FailedAlertModal({
    open,
    buttonTitle,
    onClick
}) {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="data-feed-form-dialog"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogContent>
                <Lottie
                    animationData={failedAnimation}
                    loop={true}
                />
                <Button
                    variant='contained'
                    sx={{
                        mt: 4
                    }}
                    fullWidth
                    onClick={onClick}
                >
                    {buttonTitle}
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default FailedAlertModal