import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { formatMoney } from '../../utils/constant';
import Carousel from 'react-material-ui-carousel';

function ProductsList({
    sectionTitle,
    products
}) {
    const router = useLocation();
    const { pathname } = router;

    return (
        <>
            <Typography
                variant='h6'
                sx={{
                    my: { xs: 2, sm: 3, md: 4, lg: 5, xl: 6 },
                    fontSize: { xs: "20px", sm: "25px", md: "30px", lg: "35px", xl: "40px" }
                }}
            >
                {sectionTitle}
            </Typography>
            <Grid container spacing={1}>
                {products.map((product, index) => {

                    return (
                        <Grid item xs={6} sm={6} md={6} lg={3} key={index}>
                            <Link
                                to={`/product/${product.product_name}`}
                                onClick={() => {
                                    if (pathname === `/product/${product.product_name}`) {
                                        window.scrollTo(0, 0);
                                    }
                                }}
                            >
                                <Card
                                    sx={{
                                        minWidth: 140,
                                        height: "100%",
                                        mr: 1
                                    }}
                                >
                                    <CardActionArea>
                                        <Carousel
                                            indicators={product?.product_files.length > 1}
                                            autoPlay={false}
                                        >
                                            {product?.product_files && product?.product_files.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {item.file_type === "VIDEO" ?
                                                            <video
                                                                width="100%"
                                                                controls
                                                                src={`${item.video_url}`}
                                                            /> :
                                                            item.file_type === "YOUTUBE" ?
                                                                <iframe
                                                                    width="100%"
                                                                    height="100%"
                                                                    src={`${item.video_url}`}
                                                                    title={item.product_name}
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowfullscreen
                                                                    style={{
                                                                        borderRadius: "8px",
                                                                        overflow: "hidden",
                                                                    }}
                                                                /> :
                                                                <CardMedia
                                                                    key={index}
                                                                    component="img"
                                                                    image={item?.image_url}
                                                                    alt="Picha ya Bidhaa"
                                                                />
                                                        }
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Carousel>
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    flexWrap: "wrap"
                                                }}
                                            >
                                                <Typography gutterBottom variant="h6" component="div" color="text.secondary" sx={{ textDecoration: "line-through" }}>
                                                    {formatMoney(product.product_amount)}
                                                </Typography>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    {formatMoney(product.product_promotion_amount)}
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" color="text.secondary">
                                                {product.product_name}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    );
                })
                }
            </Grid>
        </>
    )
}

export default ProductsList