export const placeOrderFormFields = [
    { name: "full_name", type: "text", label: "Jina Lako", notRequired: false },
    { name: "phone_number", type: "text", label: "Namba ya Simu", notRequired: false },
    { name: "region_id", type: "select", label: "Mkoa", items: [] },
    { name: "district_id", type: "select", label: "Wilaya", items: [] },
    { name: "ward_id", type: "select", label: "Kata", items: [], notRequired: true },
    { name: "street", type: "text", label: "Mtaa Karibu na", notRequired: true },
    {
        name: "product_quantity",
        type: "select",
        label: "Idadi ya Bidha",
        items: [
            { value: 1, label: "Moja" },
            { value: 2, label: "Mbili" },
            { value: 3, label: "Tatu" },
            { value: 4, label: "Nne" },
            { value: 5, label: "Tano" },
            { value: 6, label: "Sita" },
            { value: 7, label: "Saba" },
            { value: 8, label: "Nane" },
            { value: 9, label: "Tisa" },
            { value: 10, label: "Kumi" },
        ],
    },
    // {
    //     name: "payment_method",
    //     type: "radio",
    //     label: "Njia Ya Malipo",
    //     items: [
    //         { value: "ONLINE", label: "Lipa Sasa" },
    //         { value: "DELIVERY", label: "Lipa Ukipokea Mzigo" },
    //     ],
    // },
    { name: "payment_phone_number", type: "text", label: "Namba ya Simu Kwa Ajili Ya Malipo", notRequired: true },
]