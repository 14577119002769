//LIVE SERVER BASE URLs
export const contentsUrl = "https://www.content.afyachap.com"
export const doctorsUrl = "https://www.doctors.afyachap.com"
export const usersUrl = "https://www.users.afyachap.com"
export const webUrl = "https://afyachap.com"

//TEST SERVER BASE URLs
// export const contentsUrl = "https://www.testserver.content.afyachap.com"
// export const doctorsUrl = "https://www.testserver.doctors.afyachap.com"
// export const usersUrl = "https://www.testserver.users.afyachap.com"

//LOCAL SERVER BASE URLs
// export const contentsUrl = "http://192.168.1.172:8004"
// export const doctorsUrl = "http://192.168.1.172:8001"
// export const usersUrl = "http://192.168.1.172:8003"

/*Regions URLs */
export const getAllRegionsUrl = `${usersUrl}/api/v1/get/all/regions`

/*Districts URLs */
export const getAllDistrictsUrl = `${usersUrl}/api/v1/get/all/districts`

/*Wards URLs */
export const getAllWardsUrl = `${usersUrl}/api/v1/get/all/wards`

/*Products URLs */
export const getProductUrl = `${usersUrl}/api/v1/get/product`
export const getAllProductsUrl = `${usersUrl}/api/v1/get/products/by/pagination`
export const getAllProductsCategoriesBannersByPaginationUrl = `${usersUrl}/api/v1/get/all/products/categories/banners/by/pagination`

/*Products Ratings URLs */
export const getAllProductsRatingsByPaginationUrl = `${usersUrl}/api/v1/get/all/products/ratings/by/pagination`

/*Products Orders URLs */
export const createProductOrderUrl = `${usersUrl}/api/v1/create/web/order`
export const verifyPaymentDetailsUrl = `${usersUrl}/api/v1/web/order/verify/payment/details`