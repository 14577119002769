import { RouterProvider } from "react-router-dom";
import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { router } from "./router";
import { createEmotionCache } from './utils/create-emotion-cache';
import { lightTheme } from './theme/light';

const clientSideEmotionCache = createEmotionCache();

function App() {
  const light = lightTheme();

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={light}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
}

export default App;
